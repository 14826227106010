import { v4 as uuid } from 'uuid';

export const hasScrolled = (callback, distanceToTop = 1) => {
    const checkElement = document.createElement('div');
    const checkElementId = `check-element-${uuid()}`;
    const checkElementHeight = 1;

    checkElement.id = checkElementId;

    checkElement.style.height = `${checkElementHeight}px`;
    checkElement.style.top = `${distanceToTop}px`;
    checkElement.style.position = 'absolute';
    checkElement.style.width = '0px';
    checkElement.style.left = '0px';

    document.body.appendChild(checkElement);

    const observer = new IntersectionObserver((entries) =>
        callback(entries[0].boundingClientRect.y < -checkElementHeight)
    );

    observer.observe(document.querySelector(`#${checkElementId}`));
};
