/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import 'swiper/css';
import './vendor/fontawesome/css/all.min.css';
import './styles/index.scss';

// JavaScript
import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';

import 'bootstrap/js/dist/offcanvas';
import 'bootstrap/js/dist/tab';
import 'bootstrap/js/dist/scrollspy';

new Swiper('.swiper', {
    slidesPerView: 1.5,
    spaceBetween: 10,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    modules: [Navigation],
});

import { hasScrolled } from './js/utils';
import { toggleLogoClass } from './js/header';
import { redirectAfterSubmit } from './js/form';

(() => {
    hasScrolled((scrolled) => toggleLogoClass(scrolled, 'header__logo--moved'));

    redirectAfterSubmit();
})();
